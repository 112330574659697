<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :popupComponent="popupComponent"
        sort="site_url"
        title="Site Ownership"
        icon="mdi-lock-reset"
        stateendpoint="siteOwnership.siteOwnerships"
        :actions="actions"
        :show-default-action-buttons="false"
        :action-buttons="actionButtons"
    />
  </div>
</template>
<script>
import ListPage from "../components/ListPage";
import SiteOwnershipPopup from "../components/popups/SiteOwnershipPopup";

export default {
  name: 'SiteOwnership',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Site Ownership | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage},
  data() {
    return {
      headers: [
        {
          text: 'Site URL',
          value: 'siteUrl'
        },
        {
          text: 'Site Owner',
          value: 'siteOwnerId'
        },
        {
          text: 'Status',
          value: 'status'
        },
        {
          text: '', value: 'actions', sortable: false
        }
      ],
      columns: [
        {value: 'siteUrl'},
        {value: 'siteOwnerId'},
        {value: 'isActive'},
        {value: 'action'},
      ],
      actions: {
        load: 'loadSiteOwnerships',
        update: 'updateSiteOwnership'
      },
      actionButtons: [
        {
          category: 'edit',
          icon: 'mdi-pencil',
          color: '',
          text: 'Set Site Ownership',
          clickHandler: 'editItem'
        }
      ]
    }
  },
  computed: {
    popupComponent() {
      return SiteOwnershipPopup;
    }
  }
}
</script>
