<template>
  <base-popup
      :popup-model="show"
      :title="formTitle"
      :valid="!valid"
      :width="56"
      @onCancelButtonClick="closePopupHandler"
      @onSaveButtonClick="saveItemHandler"
  >
    <v-form
        :ref="formRef"
        v-model="valid"
        lazy-validation
    >
      <v-container class="mt-5">
        <v-row>
          <v-col
              cols="12"
              sm="12"
              md="12"
              class="pb-0"
          >
            <v-text-field
                v-model="currentItem.siteUrl"
                :rules="requiredFieldRule"
                label="Site Url"
                outlined
                append-icon="mdi-web"
                required
                dense
                readonly
            />
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="12"
              class="pb-1 pt-0"
          >
            <g-p-auto-complete
                :dense="true"
                :outlined="true"
                :search-value="currentItem.siteOwner"
                :status=true
                :validation="true"
                search-label="Site Owner"
                apiPath="site-owners"
                class="discount-field mt-2 mb-1"
                @clearSearchResult="handleClearSiteOwnerSearchResult"
                @selectSearchResult="onSelectionsSiteOwner"
            />
          </v-col>
          <v-col
              cols="12"
              sm="12"
              md="6"
              class="pb-1 pt-0"
          >
            <v-row no-gutters style="align-items: center">
              <v-col
                  cols="12"
                  xs="8"
                  md="8"
              >
                <v-subheader class="switch-btn-title">Status</v-subheader>
              </v-col>
              <v-col
                  cols="12"
                  xs="4"
                  md="4"
              >
                <v-switch
                    v-model="currentItem.isActive"
                    color="primary"
                    dense
                    inset
                    prefix="Status"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </base-popup>
</template>
<script>
import BasePopup from "./BasePopup";
import PopupMixin from "../mixins/Popup";
import GPAutoComplete from "../GPAutoComplete";

export default {
  name: 'SiteOwnershipPopup',
  components: {
    GPAutoComplete,
    BasePopup
  },
  mixins: [PopupMixin],
  computed: {
    formTitle() {
      return this.isEditMode
          ? 'Set Site Ownership'
          : 'Set Site Ownership'
    }
  },
  methods: {
    handleClearSiteOwnerSearchResult(item) {
      this.currentItem.siteOwner = null;
      this.currentItem.siteOwnerId = '';
    },
    onSelectionsSiteOwner(item) {
      this.currentItem.siteOwner = {...item};
      this.currentItem.siteOwnerId = item.id;
    },
  }
}
</script>

<style lang="scss">
.site-field .v-messages__message{
  color: red;
}
</style>